<template>
  <div ref="scrollContainer"
       class="relative min-h-screen pt-12 pb-8 lg:py-24"
       :class="images && images.length <= 3 ? ' h-[250vh]' : 'h-[300vh]'"
  >
    <Container v-if="title || copy"
               class="relative z-10 flex flex-col justify-center text-white"
    >
      <h2 v-if="title"
          class="text-4xl lg:w-3/4 lg:text-6xl leading-tighter"
      >
        {{ title }}
      </h2>
      <div v-if="copy"
           class="mt-8 text-xl lg:mt-10 lg:w-3/4 lg:text-2xl"
           v-html="copy"
      />
    </Container>
    <div class="sticky z-10 overflow-hidden top-20 md:top-[15vh]">
      <Container class="relative flex flex-col justify-center text-white">
        <div ref="imgContainer"
             class="flex space-x-8 max-w-[100vw] lg:w-[36vw] flex-shrink flex-1 min-h-0 mt-16 lg:mt-20"
             :style="{
               opacity: opacityCards
             }"
        >
          <div v-for="(img, i) in images"
               :key="i"
               class="flex-shrink-0 w-full lg:w-[36vw]"
          >
            <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
            <div class="aspect-w-1 aspect-h-1 md:aspect-w-22 md:aspect-h-15">
              <NuxtImg :src="img.src"
                       :alt="img.alt || ''"
                       class="object-cover w-full lg:w-[36vw] rounded-lg"
                       sizes="xs:87vw sm:87vw md:87vw lg:36vw xl:36vw xxl:36vw"
                       loading="lazy"
              />
            </div>
          </div>
        </div>
        <div v-if="outfit7neo"
             class="flex flex-wrap items-center justify-center gap-4 mx-auto mt-8 text-sm lg:mt-20 md:text-base"
             :style="{ opacity: opacityO7N }"
        >
          <span class="w-full text-sm text-center text-white lg:text-base md:w-auto md:text-left">Published by:</span>
          <SvgOutfit7neoLogo class="h-8 lg:h-9" />
        </div>
      </Container>
    </div>
    <div class="absolute top-0 w-full h-full">
      <div class="sticky top-0 h-screen overflow-hidden">
        <div class="absolute z-0 -top-36 -left-36">
          <div class="rounded-full blur-3xl md:blur-2xl w-[100vw] h-[100vw] md:w-[50vw] md:h-[50vw] duration-150"
               :class="[`bg-${gradientTop}`]"
               :style="{
                 transform: 'scale3d(1, 1, 1)',
                 opacity: opacity
               }"
          />
        </div>
        <div class="absolute left-0 right-0 z-0"
             :style="{
               transform: `translate3d(0, ${position}%, 0)`
             }"
        >
          <div class="rounded-full blur-3xl md:blur-2xl w-[200vw] h-[200vw] lg:w-[100vw] lg:h-[100vw] duration-150"
               :class="[`bg-${gradientBottom}`]"
               :style="{
                 transform: `scale3d(${scale}, ${scale}, 1)`
               }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { scroll } from 'motion';
import { onMounted, ref } from 'vue';
import { useDetectSize } from '../../composables/detect-size';

const size = useDetectSize();

interface GradientSliderProps {
  title?: string;
  copy?: string;
  images: {
    src: string;
    alt?: string;
  }[];
  outfit7neo?: boolean;
  gradientTop?: string;
  gradientBottom?: string;
}
withDefaults(defineProps<GradientSliderProps>(), {
  title: '',
  copy: '',
  outfit7neo: false,
  gradientTop: '',
  gradientBottom: ''
});

const scrollContainer = ref(null);
const imgContainer = ref(null);
const scrollEvent = ref(null);
const position = ref(90);
const scale = ref(1);
const opacity = ref(1);
const opacityO7N = ref(0);
const opacityCards = ref(0);

const animate = (progress: number) => {
  if (!window) return;

  if (scrollContainer.value === null) {
    return;
  }

  const maxScroll =
    size.value === 'desktop'
      ? imgContainer.value.scrollWidth -
        imgContainer.value.parentElement.offsetWidth +
        imgContainer.value.parentElement.offsetWidth / 1.35 +
        imgContainer.value.offsetLeft * 2
      : imgContainer.value.scrollWidth - imgContainer.value.parentElement.offsetWidth + imgContainer.value.offsetLeft * 2;

  const amount =
    size.value === 'desktop'
      ? progress * maxScroll - imgContainer.value.parentElement.offsetWidth / 2
      : (progress - 0.4) * 1.667 * maxScroll;

  opacity.value = Math.max(0, Math.min(1, 1 - (progress - 0.3) * 4));
  opacityO7N.value = Math.max(Math.min(1, (progress - 0.45) * 7), 0);
  opacityCards.value = Math.max(0, Math.min(1, (progress - 0.25) * 7));
  position.value = (1 - progress / 2) * 100;
  scale.value = 1 + (progress - 0.4) * 2;

  imgContainer.value.style.transform = `translate3d(${-amount}px, 0, 0)`;
};

onMounted(() => {
  scrollEvent.value = scroll(
    ({ y }) => {
      animate(y.progress);
    },
    {
      target: scrollContainer.value,
      offset: ['start end', 'end end']
    }
  );
});
</script>
